@media all and (min-width: 2300px) {
  html {
    font-size: 20px;
  }
}

@media all and (max-width: 2299px) {
  html {
    font-size: 18px;
  }
}

@media all and (max-width: 1999px) {
  html {
    font-size: 16px;
  }
}

@media all and (max-width: 1499px) {
  html {
    font-size: 13px;
  }
}

@media all and (max-width: 1199px) {
  html {
    font-size: 11px;
  }
}

@media all and (max-width: 899px) {
  html {
    font-size: 9px;
  }
}
