.privacy-policy-container {
  font-family: Arial, sans-serif;
  color: #ffffff;
  background-color: #000000;
  padding: 20px;
  padding-top: 5rem;
  margin: 0 auto;
}

.header {
  text-align: center;
}

.logo {
  font-size: 24px;
  margin-bottom: 0;
}

.privacy-title {
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 20px;
}

.update-info {
  background-color: #2c3e50;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.update-info a {
  color: #3498db;
}

.policy-section {
  margin-bottom: 20px;
}

.section-title {
  color: #f1c40f;
  font-size: 24px;
}

.summary {
  background-color: #34495e;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.policy-section h4 {
  font-size: 18px;
  margin-top: 10px;
}

.policy-section p,
.policy-section ul {
  margin-bottom: 10px;
}

.policy-section ul {
  padding-left: 20px;
}

.policy-section a {
  color: #3498db;
}
